let BASE_URL = "";

let DOCUSIGN_OAUTH_BASE_URL = "";
let DOCUSIGN_CLIENT_ID = 'ceb03626-f22b-456b-8f92-de1a5bebb40c';
let DOCUSIGN_REDIRECT_URI = '';

if (process.env.NODE_ENV !== "development") {
  BASE_URL = "https://modinsight.dev:8089/v1";
  DOCUSIGN_REDIRECT_URI = 'https://modinsight.dev';
  DOCUSIGN_OAUTH_BASE_URL = "https://account.docusign.com/oauth/auth";
} else {
  BASE_URL = 'http://localhost:8089/v1'
  DOCUSIGN_OAUTH_BASE_URL = "https://account-d.docusign.com/oauth/auth";
  DOCUSIGN_REDIRECT_URI = 'http://localhost:3000';
}

export { BASE_URL , DOCUSIGN_OAUTH_BASE_URL, DOCUSIGN_CLIENT_ID, DOCUSIGN_REDIRECT_URI };
