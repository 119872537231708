import React, { useEffect, useState, useContext } from 'react';
import ApexCharts from 'react-apexcharts';
import AuthContext from '../AuthContext';
import axios from 'axios';
import { BASE_URL } from '../config';
import '../styles/main.css'; // Assuming your CSS file

const Chart = () => {
  const { userToken } = useContext(AuthContext);
  const { userRole } = useContext(AuthContext);
  const [categoryNames, setCategoryNames] = useState([]);
  const [categoryColors, setCategoryColors] = useState([]);
  const [fileCount, setFileCount] = useState([]);

  useEffect(() => {
    const fetchDocumentData = async () => {
      try {
        const response = await axios.post(
          `${BASE_URL}/${userRole}/category/list-with-doc-count`,
          {
            page: 0,
            size: 100,
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.data.status) {
          const categories = response.data.categories;
          const fileCounts = [];
          const colors = [];
          const names = [];

          if (categories) {
            categories.forEach((c) => {
              if (c.file_count !== undefined && c.name) {
                fileCounts.push(c.file_count || 0);
                colors.push(c.category_color || '#cccccc'); // Use a default color if none is provided
                names.push(c.name);
              }
            });
          
            setCategoryNames(names);
            setCategoryColors(colors);
            setFileCount(fileCounts);
          }
        }
      } catch (error) {
        console.error('Error fetching document data:', error);
      }
    };

    fetchDocumentData();
  }, [userToken, userRole]);
 
  const options = {
    chart: { type: 'donut', height: 350 },
    labels: categoryNames, // Use category names as labels
    colors: categoryColors, // Use category colors
    legend: {
      show:true,
      position: 'right',
      formatter: function (seriesName, opts) {
        var value = '';
        if(opts?.w?.globals?.series?.[opts?.seriesIndex]){
          value = opts?.w?.globals?.series?.[opts?.seriesIndex]
        }
        // const value = opts?.w?.globals?.series?.[opts?.seriesIndex];
        return (!value || value !== undefined)
          ? `${seriesName}: ${value}`
          : `${seriesName}: 0`;
        // return "T : 1"
      },
      onItemHover: {
        highlightDataSeries: false, // Disable hover highlighting
      },
      onItemClick: {
        toggleDataSeries: false, // Disable clicking to toggle series
      },
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 400
        },
        legend: {
          position: 'right'
        }
      }
    }]
  };

  return (
    <div className="chart-wrapper p-0">
      <h2 className="chart-title m-1 p-0">Total Documents per Category</h2>
      <div id="chart" className="chart-container p-0">
        {(!fileCount || fileCount.length < 1) && (
          <div className="overlay">
            <p className="overlay-text">No data available</p>
          </div>
        )}
        <div className={fileCount.length > 0 ? '' : 'blur-chart p-0'}>
          <ApexCharts
            options={options}
            series={fileCount} // File counts as series data
            type="donut"
            height={230}
          />
        </div>
      </div>
    </div>
  );
};

export default Chart;
