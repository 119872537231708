import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import { BASE_URL } from '../config'
import '../styles/main.css'
import { BeatLoader } from 'react-spinners'
import AuthContext from '../AuthContext'

const override = {
  display: 'flex',
  margin: '0 auto',
  justifyContent: 'center',
  alignItems: 'center',
}

const Clauses = () => {
  // Hard-coded document
  const hardCodedDoc = {
    filename: "02. Generate - LLCA (Executed)",
    category_name: "Solar Tax Equity LLCAs",
    clauseDetails: {
      "Applicable Laws": `“Applicable Laws” means any applicable constitutional provision, statute, act, code (including the Code), law, regulation, rule, ordinance, order, decree, ruling, proclamation, resolution, judgment, decision or declaration of a Governmental Authority having valid jurisdiction over any Project (including any Site), any Project Company, Seller, the Company, any Equity Investor or any Affiliate of any of the foregoing, as applicable.`,
      "Capital Contribution": `“Capital Contribution” means any cash or the initial Value of any other property (net of liabilities secured by such property that the Company is considered to assume or take subject to under Section 752 of the Code) that a Member directly or indirectly contributes to the Company with respect to the Units held or purchased by such Member, including any capital contributions made by such Member pursuant to Article III, and any reference to the Capital Contributions of a Member shall include the Capital Contributions of any predecessor Holder of the Member’s Units.`,
      "Change of Member Control": `“Change of Member Control” means, with respect to any Member, an event (such as a transfer of voting securities) that causes such Member to cease to be Controlled by its Parent; provided that, for the avoidance of doubt, an event that causes a Member’s Parent to be Controlled by another Person shall not, in and of itself, constitute a Change of Member Control of such Member.`,
      "Claims": `“Claims” means all claims, suits, demands, injunctions, actions, causes of action, assessments, cleanup and remedial obligations, judgments, awards, liabilities, losses (including amounts paid in settlement of claims), damages (including any consequential, punitive, incidental or special damages recovered by any Third Party but excluding any consequential, punitive, incidental or special damages asserted by any Member or an Affiliate), fines, fees, taxes, penalties, costs and expenses of every kind and character (including litigation costs and reasonable attorneys’ and experts’ fees and expenses, including such fees and expenses at trial and on any appeal).`
    }
  }

  const [documents, setDocuments] = useState([hardCodedDoc]) // Start with hard-coded doc already in state
  const [loadingDocs, setLoadingDocs] = useState([]) // Tracks loading documents
  const [isLoading, setIsLoading] = useState(false) // Global spinner
  const { userToken } = useContext(AuthContext)

  // Filters
  const [filterClauseName, setFilterClauseName] = useState('')
  const [filterDocumentName, setFilterDocumentName] = useState('')
  const [filterCategory, setFilterCategory] = useState('')

  const fetchFileDetails = async (fileId) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/global/file-details/${fileId}`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      if (response.status === 200) {
        return { category_name: response.data.data.categoryName || 'N/A' }
      }
    } catch (error) {
      console.error('Error fetching file details:', error)
    }
    return { category_name: 'N/A' }
  }

  const fetchClauseDetailsForDoc = async (fileId) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/user/getClauses`,
        { file_id: fileId },
        { headers: { Authorization: `Bearer ${userToken}` } }
      )
      if (response.status === 200) return response.data.data
    } catch (error) {
      console.error('Error fetching clauses:', error)
    }
    return {}
  }

  const fetchDocuments = async () => {
    setIsLoading(true) // Global loader
    try {
      const response = await axios.post(
        `${BASE_URL}/user/file/list`,
        { page: 1, size: 10 },
        { headers: { Authorization: `Bearer ${userToken}` } }
      )

      if (response.data.status !== 1) {
        console.error('Failed to fetch documents:', response.data.message)
        return
      }

      const fileList = response.data.data

      // Fetch each document's details and clauses incrementally
      fileList.forEach(async (doc) => {
        setLoadingDocs((prev) => [...prev, doc._id]) // Mark document as loading

        const fileDetails = await fetchFileDetails(doc._id)
        const clauseDetails = await fetchClauseDetailsForDoc(doc._id)

        setDocuments((prevDocs) => [
          ...prevDocs,
          { ...doc, ...fileDetails, clauseDetails: clauseDetails || {} },
        ])

        setLoadingDocs((prev) => prev.filter((id) => id !== doc._id)) // Mark document as loaded
      })
    } catch (error) {
      console.error('Error fetching documents:', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchDocuments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Build a flat list of clause rows for all loaded documents
  const clauseRows = documents.flatMap((doc) =>
    Object.entries(doc.clauseDetails || {}).map(([name, text]) => ({
      clauseName: name,
      category: doc.category_name,
      documentName: doc.filename,
      clauseText: text.replace(/_/g, '"'),
    }))
  )

  // Apply filters
  const filteredClauseRows = clauseRows.filter((row) => {
    return (
      row.clauseName.toLowerCase().includes(filterClauseName.toLowerCase()) &&
      row.documentName
        .toLowerCase()
        .includes(filterDocumentName.toLowerCase()) &&
      row.category.toLowerCase().includes(filterCategory.toLowerCase())
    )
  })

  return (
    <div className="wrapper">
      <div className="container mt-4">
        <h3 className="title w-700 mt-0">Clauses</h3>

        {/* Filters */}
        <div className="row mb-3">
          <div className="col-md-4">
            <input
              type="text"
              className="form-control"
              placeholder="Filter by Clause Name"
              value={filterClauseName}
              onChange={(e) => setFilterClauseName(e.target.value)}
            />
          </div>
          <div className="col-md-4">
            <input
              type="text"
              className="form-control"
              placeholder="Filter by Document Name"
              value={filterDocumentName}
              onChange={(e) => setFilterDocumentName(e.target.value)}
            />
          </div>
          <div className="col-md-4">
            <input
              type="text"
              className="form-control"
              placeholder="Filter by Category"
              value={filterCategory}
              onChange={(e) => setFilterCategory(e.target.value)}
            />
          </div>
        </div>

        {/* Table */}
        <table className="task_tabl">
          <thead>
            <tr>
              <th>Clause Name</th>
              <th>Category</th>
              <th>Document Name</th>
              <th>Clause Text</th>
            </tr>
          </thead>
          <tbody>
            {/* Fully loaded rows */}
            {filteredClauseRows.map((row, index) => (
              <tr key={index}>
                <td>{row.clauseName}</td>
                <td>{row.category}</td>
                <td>{row.documentName}</td>
                <td>{row.clauseText}</td>
              </tr>
            ))}

            {/* Loading placeholders at the bottom */}
            {loadingDocs.length > 0 && (
              <tr>
                <td colSpan="4" className="text-center">
                  Loading clauses...
                </td>
              </tr>
            )}

            {/* Empty state */}
            {!isLoading &&
              documents.length === 0 &&
              loadingDocs.length === 0 && (
                <tr>
                  <td colSpan="4" className="text-center">
                    No documents found.
                  </td>
                </tr>
              )}
          </tbody>
        </table>

        {/* Global Loader */}
        {isLoading && (
          <div className="text-center mt-3">
            <BeatLoader color={'#000'} cssOverride={override} size={10} />
          </div>
        )}
      </div>
    </div>
  )
}

export default Clauses