import React, { useState, useContext } from 'react'
import { SketchPicker } from 'react-color'
import useClickOutside from '../hooks/useClickOutside'
import axios from 'axios'
import { BASE_URL } from '../../config'
import AuthContext from '../../AuthContext'
import '../../styles/Settings.css'

export default function CategoryColorPicker({
  selectedCategory,
  categoryColors,
  handleColorSave, // Pass this function from the parent component
}) {
  const { userToken } = useContext(AuthContext)
  const [activePicker, setActivePicker] = useState(false) // To control the visibility of the color picker
  const [tempColor, setTempColor] = useState(categoryColors[selectedCategory]) // Temporary color state

  const pickerRef = useClickOutside(() => {
    if (activePicker) setActivePicker(false)
  })

  const handleColorChange = (color) => {
    setTempColor(color.hex) // Update temporary color state with the selected color
  }

  const saveColorChange = async () => {
    if (!selectedCategory) return

    try {
      // Save the updated color to the backend
      const response = await axios.post(
        `${BASE_URL}/user/update-category-colors`,
        { categoryId: selectedCategory, color: tempColor },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )

      if (response.status === 200) {
        console.log('Category color updated successfully.')
        handleColorSave(selectedCategory, tempColor) // Call parent function to update state
      } else {
        console.error(
          'Failed to update category color: ',
          response.data.message
        )
      }
    } catch (error) {
      console.error('Error updating category color:', error)
    } finally {
      setActivePicker(false) // Close the color picker after saving
    }
  }

  return (
    <div style={{ marginTop: '20px', position: 'relative' }}>
      <div
        style={{
          width: '50px',
          height: '50px',
          backgroundColor: categoryColors[selectedCategory] || '#ffffff',
          border: '1px solid #ddd',
          borderRadius: '4px',
          cursor: 'pointer',
        }}
        onClick={() => setActivePicker(true)}
      ></div>

      {activePicker && (
        <div
          className="filters-menu"
          ref={pickerRef}
          style={{ position: 'absolute', zIndex: 10 }}
        >
          <SketchPicker
            color={tempColor || '#ffffff'}
            onChange={handleColorChange}
          />
          <button
            onClick={saveColorChange}
            style={{
              marginTop: '10px',
              padding: '8px 12px',
              backgroundColor: '#007bff',
              color: '#fff',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            Save
          </button>
        </div>
      )}
    </div>
  )
}
