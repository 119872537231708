import React, { useState, useEffect } from 'react'
import '../../styles/Settings.css'
import PasswordSettings from './PasswordSettings'
import Modal from 'react-modal'
import axios from 'axios'
import companyLogo from '../../img/organization.png' // Default company logo
import { useContext } from 'react'
import { BeatLoader } from 'react-spinners'
import AuthContext from '../../AuthContext'
import { BASE_URL } from '../../config'
import Accounts from '../Accounts'
import ChartImage from '../../img/annualAnalysis.png'
import { saveAs } from 'file-saver'
import teamsLogo from '../../img/teams.png'
import dayjs from 'dayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import CategoryColorPicker from './CategoryColorPicker'
import TeamsIntegration from './teamsIntegartion'

Modal.setAppElement('#root') // Avoid accessibility warning from Modal

export default function Settings() {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [activeTab, setActiveTab] = useState('profile') // Track active tab
  const [editField, setEditField] = useState('') // To control which field is being edited
  const [userDetails, setUserDetails] = useState({})
  const [newCompanyName, setNewCompanyName] = useState('')
  const [newPhoneNumber, setNewPhoneNumber] = useState('')
  const [isChatCreator, setChatCreator] = useState(false)
  const [newCompanyLogo, setNewCompanyLogo] = useState(null)
  const [categories, setCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [subCategories, setSubCategories] = useState([])
  const [averageScores, setAverageScores] = useState({})
  const [subcategoryAutomation, setSubcategoryAutomation] = useState({}) // New state for subcategory automation data
  const [is2FAEnabled, setIs2FAEnabled] = useState(false)
  const [error, setError] = useState('')
  const [editMode, setEditMode] = useState(false) // New state for edit mode
  const { userToken, userEmail, userRole } = useContext(AuthContext)
  const [selectedRisk, setSelectedRisk] = useState({})
  const [alertInputs, setAlertInputs] = useState({
    name: '',
    associatedView: '',
    frequency: 'Weekly',
  })
  const [alerts, setAlerts] = useState([])
  const [userAlertSettings, setUserAlertSettings] = useState({
    user_invited_enabled: false,
    document_uploaded_enabled: false,
    file_completed_enabled: false,
  })
  const [slackDetails, setSlackDetails] = useState('')
  const [teamsDetails, setTeamsDetails] = useState('')
  const [loadingReport, setLoadingReport] = useState(null) // Tracks which button is loading
  const [successMessage, setSuccessMessage] = useState('') // State for success messages
  const [selectedDate, setSelectedDate] = useState('')
  const [categoryColors, setCategoryColors] = useState({
    'Master Services Agreement (MSA)': '#007bff',
    'Non Disclosure Agreement (NDA)': '#28a745',
    'Data Processing Agreement (DPA)': '#ffc107',
    'Statement of Work (SOW)': '#6c757d',
    Amendment: '#17a2b8',
    Addendum: '#dc3545',
  })
  const override = {
    display: 'flex',
    margin: '0 auto',
    borderColor: '#007bff',
    justifyContent: 'center',
  }

  // Handle Teams Authentication and save to sessionStorage
  useEffect(() => {
    const handleTeamsAuth = async () => {
      const params = new URLSearchParams(window.location.search)
      const code = params.get('code')

      if (code) {
        try {
          const response = await axios.post(`${BASE_URL}/teams/auth`, { code })
          console.log('Response from Teams Auth:', response.data)

          if (response.data.success) {
            const email = response.data.userDetails.mail
            console.log('User Email Retrieved:', email)

            if (email) {
              setTeamsDetails(email) // Update React state
              sessionStorage.setItem('teamsDetails', email) // Save to sessionStorage
              console.log('teamsDetails Saved:', email)

              // Clear 'code' from URL
              const url = new URL(window.location.href)
              url.searchParams.delete('code')
              window.history.replaceState(null, '', url.toString())
            } else {
              console.error('Email is undefined in response.')
            }
          }
        } catch (error) {
          console.error('Error during Teams authentication:', error)
        }
      } else {
        // Check sessionStorage for teamsDetails on initial load
        const storedTeamsDetails = sessionStorage.getItem('teamsDetails')
        if (storedTeamsDetails) {
          setTeamsDetails(storedTeamsDetails)
          console.log(
            'Restored teamsDetails from sessionStorage:',
            storedTeamsDetails
          )
        }
      }
    }

    handleTeamsAuth()
  }, [])

  // Debugging: Log teamsDetails whenever it updates
  useEffect(() => {
    console.log('Updated teamsDetails:', teamsDetails)
  }, [teamsDetails])

  useEffect(() => {
    const fetchAlerts = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/alerts`, {
          headers: { Authorization: `Bearer ${userToken}` },
        })
        if (response.data.success) setAlerts(response.data.alerts)
      } catch (error) {
        console.error('Error fetching alerts:', error)
      }
    }
    const fetchUserAlertSettings = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/alerts/notifications`, {
          headers: { Authorization: `Bearer ${userToken}` },
        })
        if (response.data.success && response.data.alertSettings) {
          setUserAlertSettings(response.data.alertSettings)
        }
      } catch (error) {
        console.error('Error fetching alert settings:', error)
      }
    }
    fetchAlerts()
    fetchUserAlertSettings()
  }, [userToken])

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.post(
          `${BASE_URL}/${userRole}/getUser`,
          { userEmail },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              'Content-Type': 'application/json',
            },
          }
        )
        console.log('user', response.data)
        if (response.data.status === 1) {
          setUserDetails(response.data.data)
          setNewCompanyLogo(response.data.url)
          setNewCompanyName(response.data.data.company_name)
          setNewPhoneNumber(response.data.data.phone)
          setIs2FAEnabled(response.data.data.two_factor_auth)
        } else {
          setError(response.data.message)
        }
      } catch (error) {
        setError('Failed to fetch user details')
      }
    }
    fetchUserDetails()
  }, [userToken, userEmail, userRole])

  useEffect(() => {
    const fetchUserColors = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/user/getColors`, {
          headers: { Authorization: `Bearer ${userToken}` },
        })
        setCategoryColors(response.data.category_colors)
      } catch (error) {
        console.error('Error fetching user colors:', error)
      }
    }

    fetchUserColors()
  }, [userToken])

  const toggle2FA = async () => {
    try {
      const new2FAStatus = !is2FAEnabled
      const response = await axios.post(
        `${BASE_URL}/${userRole}/updateUser`,
        { two_factor_auth: new2FAStatus }, // Pass the 2FA status here
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )
      if (response.data.status === 1) {
        setIs2FAEnabled(new2FAStatus) // Update the 2FA state
      } else {
        setError(response.data.message)
      }
    } catch (error) {
      setError('Failed to update 2FA settings')
    }
  }

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/user/get-categories`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        setCategories(response.data)
      } catch (error) {
        console.error('Error fetching categories:', error)
      }
    }
    fetchCategories()
    getSlackInfo()
  }, [userToken])

  const handleCategoryChange = (event) => {
    const categoryId = event.target.value
    setSelectedCategory(categoryId)
    fetchSubCategories(categoryId)
    fetchSubCategoryAutomation(userDetails.team_id, categoryId) // Fetch automation data on category selection
  }

  const fetchSubCategories = async (questionCategoryId) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/user/get-subcategories/${questionCategoryId}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )

      setSubCategories(response.data.subCategories)
      setAverageScores(response.data.averageScores)
    } catch (error) {
      console.error('Error fetching subcategories:', error)
    }
  }

  // Fetch or create subcategory automation
  const fetchSubCategoryAutomation = async (teamId, qcId) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/user/get-subcategory-automation/${teamId}/${qcId}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      setSubcategoryAutomation(response.data.subcategory_status || {})
    } catch (error) {
      console.error('Error fetching subcategory automation:', error)
    }
  }

  const handleDateSelection = (date) => {
    setSelectedDate(date)
    console.log('Selected date:', date)
  }

  async function generateAndSendReport(frequency) {
    const chartDataURL = generateChartImage() // Generate the chart on frontend
    const reportData = {
      frequency: frequency,
      chartData: chartDataURL,
      userEmail: userEmail, // Get the user email from context or props
    }

    try {
      const response = await axios.post(
        `${BASE_URL}/alerts/generate-report`,
        reportData,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      if (response.data.success) {
        console.log('Report successfully sent!')
      }
    } catch (error) {
      console.error('Error generating and sending report:', error)
    }
  }

  // Function to generate chart image as base64
  function generateChartImage() {
    // Create an HTML Image element
    const img = new Image()
    img.src = ChartImage // Adjust the path based on your setup

    // Convert image to base64 once loaded
    return new Promise((resolve, reject) => {
      img.onload = () => {
        // Create a canvas to draw the image
        const canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height
        const ctx = canvas.getContext('2d')

        // Draw the image onto the canvas
        ctx.drawImage(img, 0, 0)

        // Convert the canvas to base64
        const base64Image = canvas.toDataURL('image/png')
        resolve(base64Image)
      }
      img.onerror = (error) => reject(error)
    })
  }

  const openModal = (field) => {
    setEditField(field)
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  const handleFileChange = (e) => {
    setNewCompanyLogo(e.target.files[0])
  }

  const handleUpdate = async () => {
    try {
      const formData = new FormData()
      formData.append('company_name', newCompanyName)
      formData.append('phone', newPhoneNumber)
      formData.append('userEmail', userEmail)
      if (newCompanyLogo) {
        formData.append('file', newCompanyLogo)
      }
      const response = await axios.post(
        `${BASE_URL}/${userRole}/updateUser`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      if (response.data.status === 1) {
        setUserDetails(response.data.data) // Update the state with new details
        setNewCompanyLogo(response.data.url)
        closeModal() // Close the modal
      } else {
        setError(response.data.message)
      }
    } catch (error) {
      setError('Failed to update user details')
    }
  }

  const handleUpdateUser = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/${userRole}/updateUser`,
        {
          company_name: newCompanyName,
          phone: newPhoneNumber,
          userEmail: userEmail,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      if (response.data.status === 1) {
        setUserDetails(response.data.data) // Update the state with new details
        closeModal() // Close the modal
      } else {
        setError(response.data.message)
      }
    } catch (error) {
      setError('Failed to update user details')
    }
  }

  const handleEmailChange = (subcategoryName, value) => {
    setSubcategoryAutomation((prev) => ({
      ...prev,
      [subcategoryName]: {
        ...prev[subcategoryName],
        escalate: value,
      },
    }))
  }

  const handleTabChange = (tab) => {
    setActiveTab(tab)
    setError('')
    setSuccessMessage('')
  }

  const handleAlertToggle = async (alertType) => {
    const isEnabled = !userAlertSettings[alertType]
    const enabledDate = isEnabled ? new Date() : null

    const updatedSettings = {
      ...userAlertSettings,
      [alertType]: !userAlertSettings[alertType],
      user_id: userDetails._id,
      [`${alertType}_enabledDate`]: enabledDate,
    }
    setUserAlertSettings(updatedSettings)

    try {
      await axios.post(`${BASE_URL}/alerts/notifications`, updatedSettings, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
    } catch (error) {
      console.error('Error updating alert settings:', error)
    }
  }

  // const handleCreateAlert = async () => {
  //   try {
  //     const response = await axios.post(
  //       `${BASE_URL}/alerts/custom`,
  //       alertInputs,
  //       { headers: { Authorization: `Bearer ${userToken}` } }
  //     )
  //     if (response.data.success) {
  //       setAlerts((prevAlerts) => [...prevAlerts, response.data.alert])
  //       setAlertInputs({ name: '', associatedView: '', frequency: 'Weekly' })
  //     }
  //   } catch (error) {
  //     console.error('Error creating alert:', error)
  //   }
  // }

  const handleAlertInputChange = (field, value) => {
    setAlertInputs((prev) => ({ ...prev, [field]: value }))
  }

  const toggleEditMode = () => {
    setEditMode(!editMode)
  }

  useEffect(() => {
    console.log(subcategoryAutomation)
  }, [subcategoryAutomation])

  const handleEditSubmit = async () => {
    // Add logic for submitting edits
    try {
      const teamId = userDetails.team_id
      const qcId = selectedCategory
      const response = await axios.post(
        `${BASE_URL}/user/update-subcategory-automation/${teamId}/${qcId}`,
        { subcategory_status: subcategoryAutomation },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )
      console.log('Update response:', response.data)
      setEditMode(false)
    } catch (error) {
      console.error('Error updating subcategory automation:', error)
    }
  }

  const getSlackInfo = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/user/slc/get-linked-account`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      if (
        response.data.status &&
        response.data.data &&
        response.data.data.name
      ) {
        setSlackDetails(response.data.data.name)
      }
    } catch (error) {
      console.error('Error fetching subcategories:', error)
    }
  }
  console.log('Selected Date:', selectedDate)
  const downloadReport = async (reportType) => {
    setLoadingReport(reportType) // Set the loading state for the clicked button
    setError('')
    setSuccessMessage('')

    if (!selectedDate) {
      setError('Please select a valid time frame.')
      setLoadingReport(null)
      return
    }

    try {
      const response = await axios.get(
        `${BASE_URL}/alerts/download-report/${reportType}?date=${selectedDate}`,
        {
          responseType: 'blob', // Ensure the response is handled as a file
          headers: {
            Authorization: `Bearer ${userToken}`,
          }, // Replace with your auth token retrieval
        }
      )
      if (response.data.size === 0) {
        setError(`No ${reportType} report found for the selected time frame.`)
      } else {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${reportType}-Analysis-Report.pdf`)
        document.body.appendChild(link)
        link.click()
        link.remove()

        setSuccessMessage(`${reportType} report downloaded successfully.`)
      }
    } catch (error) {
      console.error(`Error downloading ${reportType} report:`, error)
      setError(`Failed to download the ${reportType} report. Please try again.`)
    } finally {
      setLoadingReport(null) // Reset the loading state
    }
  }

  const handleColorSave = (categoryId, colorHex) => {
    setCategoryColors((prevColors) => ({
      ...prevColors,
      [categoryId]: colorHex,
    }))
  }

  return (
    <div className="wrapper">
      <div className="content-area px-4 pt-4" style={{ marginLeft: '0px' }}>
        <div className="settings-page pt-0">
          <h3 className="title w-700 mt-0">Settings</h3>
          <div className="cards px-4 pt-4 pb-2 mb-5 mt-3">
            <div className="settings-nav">
              <button
                onClick={() => handleTabChange('profile')}
                className={activeTab === 'profile' ? 'active' : ''}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-user"
                >
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>
                &nbsp;&nbsp; Profile
              </button>
              <button
                onClick={() => handleTabChange('riskAutomation')}
                className={activeTab === 'riskAutomation' ? 'active' : ''}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-alert-circle"
                >
                  <circle cx="12" cy="12" r="10"></circle>
                  <line x1="12" y1="8" x2="12" y2="12"></line>
                  <line x1="12" y1="16" x2="12.01" y2="16"></line>
                </svg>
                &nbsp;&nbsp; Risk Automation
              </button>
              <button
                onClick={() => handleTabChange('account')}
                className={activeTab === 'account' ? 'active' : ''}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-tool"
                >
                  <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"></path>
                </svg>
                &nbsp;&nbsp; Account
              </button>
              <button
                onClick={() => handleTabChange('alerts')}
                className={activeTab === 'alerts' ? 'active' : ''}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-alert-triangle"
                >
                  <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                  <line x1="12" y1="9" x2="12" y2="13"></line>
                  <line x1="12" y1="17" x2="12.01" y2="17"></line>
                </svg>
                &nbsp;&nbsp; Alerts
              </button>

              <button
                onClick={() => handleTabChange('integrations')}
                className={activeTab === 'integrations' ? 'active' : ''}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-airplay"
                >
                  <path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1"></path>
                  <polygon points="12 15 17 21 7 21 12 15"></polygon>
                </svg>
                &nbsp;&nbsp; Integrations
              </button>

              <button
                onClick={() => handleTabChange('reports')}
                className={activeTab === 'reports' ? 'active' : ''}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-download-cloud"
                >
                  <polyline points="8 17 12 21 16 17"></polyline>
                  <line x1="12" y1="12" x2="12" y2="21"></line>
                  <path d="M20.88 18.09A5 5 0 0 0 18 8h-1.26A8 8 0 1 0 4 16.29"></path>
                </svg>
                &nbsp;&nbsp; Reports
              </button>
              <button
                onClick={() => handleTabChange('filters')}
                className={activeTab === 'filters' ? 'active' : ''}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-filter"
                >
                  <polygon points="22 3 2 3 10 13 10 19 14 21 14 13 22 3"></polygon>
                </svg>
                &nbsp;&nbsp; Filters
              </button>
            </div>
            <div className="tab-content pt-0 px-2 tb_contwo">
              {activeTab === 'profile' && (
                <div className="profile-settings">
                  <h2 className="cards_headings22 m-0">Profile Settings</h2>
                  <div className="settings-item">
                    <span>First name</span>
                    <span className="fnt-capt">
                      {userDetails.fname || 'N/A'}
                    </span>
                  </div>
                  <div className="settings-item">
                    <span>Last name</span>
                    <span className="fnt-capt">
                      {userDetails.lname || 'N/A'}
                    </span>
                  </div>
                  <div className="settings-item">
                    <span>Email</span>
                    <div>
                      <span style={{ textTransform: 'lowercase' }}>
                        {userDetails.email || 'N/A'}
                      </span>
                    </div>
                  </div>
                  <div className="settings-item">
                    <span>Company Name</span>
                    <div>
                      <span className="fnt-capt">
                        {userDetails.company_name || 'N/A'}
                      </span>
                      <button onClick={() => openModal('company_name')}>
                        Edit
                      </button>
                    </div>
                  </div>
                  <div className="settings-item">
                    <span>Company logo</span>
                    <div>
                      <img
                        src={newCompanyLogo || companyLogo}
                        alt="Company Logo"
                        className="company-logo"
                        style={{
                          width: '100px',
                          height: '100px',
                          borderRadius: '5px',
                        }}
                      />
                      <button onClick={() => openModal('company_logo')}>
                        Edit
                      </button>
                    </div>
                  </div>
                  <div className="settings-item">
                    <span>Two-Factor Authentication (2FA)</span>
                    <div>
                      <div class="container_cheks_e">
                        <input
                          type="checkbox"
                          class="checkbox_chss_fee"
                          id="checkbox"
                          checked={is2FAEnabled}
                          onChange={toggle2FA}
                        />
                        <label class="switch_chss_fee" for="checkbox">
                          <span class="slider_fee_tgl"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="settings-item bord_non">
                    <span>Reset Password</span>
                    <div>
                      <button
                        className="restet_pass_btn"
                        onClick={() => openModal('reset_password')}
                      >
                        Reset Password
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 'riskAutomation' && (
                <div className="questions-settings">
                  <div className="dropdown-container m-0">
                    <h2 className="cards_headings22 m-0">Categories</h2>
                    <select
                      className="dropdown-select mt-2"
                      onChange={handleCategoryChange}
                      value={selectedCategory || ''}
                    >
                      <option value="" disabled>
                        Select a Category
                      </option>
                      {categories.map((category) => (
                        <option key={category._id} value={category._id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="edit-buttons">
                    {editMode ? (
                      <>
                        <button
                          onClick={handleEditSubmit}
                          className="edit-button"
                        >
                          Submit
                        </button>
                        <button
                          onClick={toggleEditMode}
                          className="edit-button"
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      <button onClick={toggleEditMode} className="edit-button">
                        Edit
                      </button>
                    )}
                  </div>
                  {subCategories.length > 0 && (
                    <div className="subcategories-container">
                      <table className="task_tabl">
                        <thead>
                          <tr>
                            <th>SubCategories</th>
                            <th>Fast Track</th>
                            <th>Pause</th>
                            <th>Escalate</th>
                            <th>Average Score</th>
                          </tr>
                        </thead>
                        <tbody>
                          {subCategories.map((sub) => (
                            <tr key={sub.subcategory_id}>
                              <td>{sub.subcategory_name}</td>
                              <td>
                                <select
                                  className={`risk-dropdown ${
                                    selectedRisk[sub.subcategory_id]
                                  }`}
                                  onChange={(e) => {
                                    setSelectedRisk({
                                      ...selectedRisk,
                                      [sub.subcategory_id]: e.target.value,
                                    })
                                    setSubcategoryAutomation({
                                      ...subcategoryAutomation,
                                      [sub.subcategory_name]: {
                                        ...subcategoryAutomation[
                                          sub.subcategory_name
                                        ],
                                        fastTrack: e.target.value,
                                      },
                                    })
                                  }}
                                  value={
                                    subcategoryAutomation[sub.subcategory_name]
                                      ?.fastTrack || 'Off'
                                  }
                                  disabled={!editMode}
                                >
                                  <option value="Low Flexibility">
                                    Low Flexibility
                                  </option>
                                  <option value="Medium Flexibility">
                                    Medium Flexibility
                                  </option>
                                  <option value="High Flexibility">
                                    High Flexibility
                                  </option>
                                  <option value="Off">Off</option>
                                </select>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  placeholder="Enter details"
                                  className="pause-input"
                                  value={
                                    subcategoryAutomation[sub.subcategory_name]
                                      ?.pause || ''
                                  }
                                  onChange={(e) =>
                                    setSubcategoryAutomation({
                                      ...subcategoryAutomation,
                                      [sub.subcategory_name]: {
                                        ...subcategoryAutomation[
                                          sub.subcategory_name
                                        ],
                                        pause: e.target.value,
                                      },
                                    })
                                  }
                                  disabled={!editMode}
                                />
                              </td>
                              <td>
                                <input
                                  type="email"
                                  placeholder="Enter email"
                                  className="email-input"
                                  value={
                                    subcategoryAutomation[sub.subcategory_name]
                                      ?.escalate || ''
                                  }
                                  onChange={(e) =>
                                    handleEmailChange(
                                      sub.subcategory_name,
                                      e.target.value
                                    )
                                  }
                                  disabled={!editMode}
                                />
                              </td>
                              <td>
                                {averageScores[sub.subcategory_name] !==
                                undefined
                                  ? averageScores[sub.subcategory_name].toFixed(
                                      2
                                    )
                                  : 'N/A'}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
              {activeTab === 'account' && (
                <div className="profile-settings">
                  <Accounts />
                </div>
              )}
              {activeTab === 'alerts' && (
                <div className="alerts-section">
                  <h3 className="cards_headings22 m-0 w-700">Active Alerts</h3>
                  <div className="standard-alerts mt-3">
                    {alerts.map((alert) => (
                      <div key={alert.alert_id} className="alert-item">
                        <div>
                          <h3 className="alert-name">{alert.alert_name}</h3>
                          <p className="alert-description">
                            {alert.alert_description}
                          </p>
                        </div>
                        <div className="alert-controls">
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={
                                userAlertSettings[
                                  `${alert.alert_id}_enabled`
                                ] || false
                              }
                              onChange={() =>
                                handleAlertToggle(`${alert.alert_id}_enabled`)
                              }
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* Can manually send some reports [Future] */}
                  {/* <button onClick={() => generateAndSendReport('weekly')}>
                    Generate Weekly Report
                  </button>
                  <button onClick={() => generateAndSendReport('monthly')}>
                    Generate Monthly Report
                  </button>
                  <button onClick={() => generateAndSendReport('annual')}>
                    Generate Annual Report
                  </button> */}
                  {/* <div className="custom-alerts mt-5">
                    <h3>Create Custom Alert</h3>
                    <div className="alert-form">
                      <input
                        type="text"
                        placeholder="Alert Name"
                        value={alertInputs.name}
                        onChange={(e) =>
                          handleAlertInputChange('name', e.target.value)
                        }
                      />
                      <input
                        type="text"
                        placeholder="Associated View"
                        value={alertInputs.associatedView}
                        onChange={(e) =>
                          handleAlertInputChange(
                            'associatedView',
                            e.target.value
                          )
                        }
                      />
                      <select
                        value={alertInputs.frequency}
                        onChange={(e) =>
                          handleAlertInputChange('frequency', e.target.value)
                        }
                      >
                        <option value="Daily">Daily</option>
                        <option value="Weekly">Weekly</option>
                        <option value="Monthly">Monthly</option>
                      </select>
                      <button
                        className="create-alert-btn"
                        onClick={handleCreateAlert}
                      >
                        Create Alert
                      </button>
                    </div>
                  </div> */}
                </div>
              )}
              {activeTab === 'integrations' && (
                <div className="integrations-section">
                  <h3 className="cards_headings22 m-0 w-700">Integrations</h3>
                  <div className="mt-3 integrations_tbs_de">
                    {slackDetails ? (
                      <>
                        <img
                          alt="Add to Slack"
                          className="d-block mb-2"
                          height="40"
                          width="139"
                          src="https://platform.slack-edge.com/img/add_to_slack.png"
                          srcset="https://platform.slack-edge.com/img/add_to_slack.png 1x,
                https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                        />
                        Connected "{slackDetails}"
                      </>
                    ) : (
                      <a href="https://slack.com/oauth/v2/authorize?client_id=7984471976947.7981726618741&scope=channels:read,channels:join,users:read,channels:write.invites,groups:write.invites&redirect_uri=https://modinsight.dev/slack-connect">
                        <img
                          alt="Add to Slack"
                          height="40"
                          width="139"
                          src="https://platform.slack-edge.com/img/add_to_slack.png"
                          srcset="https://platform.slack-edge.com/img/add_to_slack.png 1x,
                https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                        />
                      </a>
                    )}
                  </div>
                  <div className="integration-modules">
                    {/* Teams Integration */}
                    <div className="mt-3 integrations_tbs_de">
                      {teamsDetails ? (
                        <>
                          <p>Connected to Teams as "{teamsDetails}"</p>
                          {/* <TeamsIntegration /> */}
                        </>
                      ) : (
                        <a
                          href="https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=1ea6b3ca-8ded-4b01-8711-6db8e268fb66&response_type=code&redirect_uri=http://localhost:3000/settings&scope=https://graph.microsoft.com/.default openid profile email"
                          rel="noopener noreferrer"
                        >
                          <button
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              gap: '10px',
                              backgroundColor: '#FFFFFF',
                              border: '1px solid #DDDDDD',
                              borderRadius: '8px',
                              padding: '10px 15px',
                              cursor: 'pointer',
                              marginLeft: '0px',
                              boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
                            }}
                          >
                            <img
                              alt="Connect to Teams"
                              height="24"
                              width="24"
                              src={teamsLogo}
                              style={{ objectFit: 'contain' }}
                            />
                            <span
                              style={{
                                fontSize: '14px',
                                fontWeight: '500',
                                color: '#000000',
                              }}
                            >
                              Add to Teams
                            </span>
                          </button>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 'reports' && (
                <div>
                  <h3 className="cards_headings22 m-0 w-700">Reports</h3>
                  <div className="standard-alerts mt-3">
                    {['Weekly', 'Monthly', 'Annual'].map((reportType) => (
                      <div key={reportType} className="alert-item">
                        <div>
                          <h3 className="alert-name">{`${reportType} Reports`}</h3>
                          <p className="alert-description">
                            Download the {reportType.toLowerCase()} Analysis
                            report.
                          </p>
                          <div className="date-picker-container">
                            {reportType === 'Weekly' && (
                              <input
                                type="week"
                                className="styled-dropdown"
                                value={selectedDate}
                                onChange={(e) =>
                                  setSelectedDate(e.target.value)
                                }
                              />
                            )}
                            {reportType === 'Monthly' && (
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                  <DatePicker
                                    label={'select month'}
                                    views={['month', 'year']}
                                    onChange={(newValue) => {
                                      const formattedDate =
                                        dayjs(newValue).format('YYYY-MM')
                                      setSelectedDate(formattedDate)
                                    }}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>

                              // <MonthPicker
                              //   selectedDate={selectedDate}
                              //   setSelectedDate={setSelectedDate}
                              // />
                              // <input
                              //   type="month"
                              //   className="styled-dropdown"
                              //   value={selectedDate}
                              //   onChange={(e) =>
                              //     setSelectedDate(e.target.value)
                              //   }
                              // />
                            )}
                            {reportType === 'Annual' && (
                              // <input
                              //   type="number"
                              //   min="1900"
                              //   max="2100"
                              //   className="styled-dropdown"
                              //   placeholder="Enter Year"
                              //   value={selectedDate}
                              //   onChange={(e) =>
                              //     setSelectedDate(e.target.value)
                              //   }
                              // />
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                  <DatePicker
                                    label={'select year'}
                                    views={['year']}
                                    onChange={(newValue) => {
                                      const year = dayjs(newValue).year()
                                      setSelectedDate(year)
                                    }}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            )}
                            <p className="alert-description">
                              Specify Duration for your{' '}
                              {reportType.toLowerCase()} Report{' '}
                            </p>
                          </div>
                        </div>
                        <div className="alert-controls">
                          <button
                            className="download-report-btn"
                            onClick={() =>
                              downloadReport(reportType.toLowerCase())
                            }
                            disabled={
                              loadingReport === reportType.toLowerCase()
                            } // Disable button while loading
                          >
                            {loadingReport === reportType.toLowerCase() ? (
                              <tr>
                                <td colSpan={8}>
                                  <BeatLoader
                                    color={'#007bff'}
                                    loading={loadingReport}
                                    cssOverride={override}
                                    size={10}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                  />
                                </td>
                              </tr>
                            ) : (
                              <>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-download-cloud"
                                  style={{ marginRight: '8px' }} // Add spacing between icon and text
                                >
                                  <polyline points="8 17 12 21 16 17"></polyline>
                                  <line x1="12" y1="12" x2="12" y2="21"></line>
                                  <path d="M20.88 18.09A5 5 0 0 0 18 8h-1.26A8 8 0 1 0 4 16.29"></path>
                                </svg>
                                Download
                              </>
                            )}
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                  {error && <p className="error-message">{error}</p>}
                  {successMessage && (
                    <p className="success-message">{successMessage}</p>
                  )}
                </div>
              )}
              {activeTab === 'filters' && (
                <div className="filters-menu">
                  <h4>Filters Menu</h4>
                  <p>Customize category colors for the reports:</p>
                  <select
                    value={selectedCategory || ''}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    className="dropdown-select"
                  >
                    <option value="" disabled>
                      Select a Category
                    </option>
                    {categories.map((category) => (
                      <option key={category._id} value={category._id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                  {selectedCategory && (
                    <div style={{ marginTop: '20px' }}>
                      <h5>Select Color</h5>
                      <CategoryColorPicker
                        selectedCategory={selectedCategory}
                        categoryColors={categoryColors}
                        handleColorSave={handleColorSave}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Modal for updating company name, phone number, and logo */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="Modal"
        overlayClassName="Overlay"
      >
        <button onClick={closeModal} className="close-modal p-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-x"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
        {editField === 'company_name' && (
          <div>
            <h2 className="modal-headings m-0">Edit Company Name</h2>
            <input
              className="inptcmn wid300px mt-3"
              type="text"
              value={newCompanyName}
              onChange={(e) => setNewCompanyName(e.target.value)}
            />
            <button className="go-back" onClick={handleUpdateUser}>
              Update
            </button>
          </div>
        )}

        {editField === 'phone' && (
          <div>
            <h2 className="modal-headings m-0">Edit Phone Number</h2>
            <input
              type="text"
              className="inptcmn"
              value={newPhoneNumber}
              onChange={(e) => setNewPhoneNumber(e.target.value)}
            />
            <button className="go-back" onClick={handleUpdateUser}>
              Update
            </button>
          </div>
        )}

        {editField === 'company_logo' && (
          <div>
            <h2 className="modal-headings mx-0 mt-0 mb-3">Edit Company Logo</h2>
            <input
              type="file"
              className="inptcmn"
              onChange={handleFileChange}
            />
            <button className="go-back" onClick={handleUpdate}>
              Update
            </button>
          </div>
        )}

        {editField === 'reset_password' && <PasswordSettings />}

        {editField === '2fa' && (
          <div>
            <h2 className="modal-headings m-0">
              Toggle Two-Factor Authentication
            </h2>
            <p>Enable or disable 2FA for added security.</p>
            <label className="switch">
              <input
                type="checkbox"
                checked={is2FAEnabled}
                onChange={() => setIs2FAEnabled(!is2FAEnabled)}
              />
              <span className="slider round"></span>
            </label>
            <button className="go-back" onClick={handleUpdateUser}>
              {is2FAEnabled ? 'Disable 2FA' : 'Enable 2FA'}
            </button>
          </div>
        )}
      </Modal>
    </div>
  )
}
